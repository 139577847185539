import { isAfter, isBefore, isSameDay, isValid } from 'date-fns';
import type { DateInput } from '@/types/global';
import { useDateUtils } from '@/composables/useDateUtils';

export const useRule = () => {
  const { t } = useI18n();
  const { formatDate } = useDateUtils();

  const _message = (key: string, params?: Record<string, unknown>) =>
    params ? t(`error.${key}`, params) : t(`error.${key}`);

  const requiredValidator = (value: unknown) => {
    if (Array.isArray(value)) {
      return !!value.length || _message('required');
    }
    if (typeof value === 'string') {
      return !!value.length || _message('required');
    }
    return (value !== null && value !== undefined) || _message('required');
  };

  const getDateForRelativeRule = (input: DateInput, comparing: DateInput) => {
    if (!comparing || !input) {
      return true;
    }

    const comparingDate = new Date(comparing);
    const inputDate = new Date(input);
    if (!isValid(comparingDate) || !isValid(inputDate)) {
      console.error(
        `ComparingDate ${comparingDate} or inputDate ${inputDate} is invalid!`,
      );
    }

    return { comparingDate, inputDate };
  };

  const isSameOrBeforeDayRule = (input: DateInput, comparing: DateInput) => {
    const relativeDates = getDateForRelativeRule(input, comparing);
    if (relativeDates === true) {
      return relativeDates;
    }

    const { comparingDate, inputDate } = relativeDates;
    return (
      isSameDay(inputDate, comparingDate) ||
      isBefore(inputDate, comparingDate) ||
      _message('dateMustBeSameOrBefore__date', {
        date: formatDate(comparingDate),
      })
    );
  };

  const isSameOrAfterDayRule = (input: DateInput, comparing: DateInput) => {
    const relativeDates = getDateForRelativeRule(input, comparing);
    if (relativeDates === true) {
      return relativeDates;
    }

    const { comparingDate, inputDate } = relativeDates;
    return (
      isSameDay(inputDate, comparingDate) ||
      isAfter(inputDate, comparingDate) ||
      _message('dateMustBeSameOrAfter__date', {
        date: formatDate(comparingDate),
      })
    );
  };

  const emailValidator = (value: string) =>
    /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) || _message('emailInvalid');

  const createLengthValidator =
    (length: number, key: string) => (value: string | undefined) =>
      value ? value.length < length || _message(key) : true;

  const timeSpanValidator = (value: string) =>
    /^\d\d:\d\d$/.test(value) || _message('timeSpanInvalid');

  return {
    isSameOrBeforeDayRule,
    isSameOrAfterDayRule,
    requiredValidator,
    emailValidator,
    createLengthValidator,
    timeSpanValidator,
  };
};
