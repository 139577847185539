import type { DateInput } from '@/types/global';
import { format, formatISO } from 'date-fns';
import { useUserStore } from '@/store/user';
import { parseISODuration } from '@/utils/time';

export const useDateUtils = () => {
  const { loggedUser } = useUserStore();

  const _format = (value: string | Date, formatToken: string) => {
    return format(value, formatToken);
  };

  const formatToISODate = (value: DateInput) => {
    if (!value) {
      return '';
    }

    return formatISO(value, { representation: 'date' });
  };

  const formatTimeSpan = (value: string) => {
    if (!value) {
      return '';
    }

    const duration = parseISODuration(value);
    const date = new Date(
      duration.years || 0,
      duration.months || 0,
      duration.days,
      duration.hours,
      duration.minutes,
      duration.seconds,
    );

    return _format(date, 'HH:mm');
  };

  const formatDate = (value: DateInput) => {
    if (!value) {
      return '';
    }

    return _format(value, loggedUser!.dateFormat.value);
  };

  const formatDateTime = (value: DateInput) => {
    if (!value) {
      return '';
    }

    return _format(
      value,
      `${loggedUser!.dateFormat.value} ${loggedUser!.timeFormat.value}`,
    );
  };

  return {
    formatTimeSpan,
    formatDate,
    formatDateTime,
    formatToISODate,
  };
};
