<template>
  <v-text-field
    v-model="localValue"
    :append-inner-icon="
      props.icon ??
      (!obscure ? undefined : visible ? 'i-mdi:eye-off' : 'i-mdi:eye')
    "
    :type="!obscure || visible ? 'text' : 'password'"
    :label="labelKey ? $t(labelKey) : undefined"
    :placeholder="placeholderKey ? $t(placeholderKey) : undefined"
    :rules="fieldRules"
    :required="required"
    validate-on="lazy blur"
    v-bind="$attrs"
    @click:append-inner="handleClick"
  >
    <template v-for="slotName of slotNames" #[slotName]>
      <slot :name="slotName" />
    </template>
  </v-text-field>
</template>

<script setup lang="ts">
import type { VTextField } from 'vuetify/components';
import { useRule } from '@/composables/useRule';

// sfc-compiler has problem with inheriting complex types
// from libraries, therefor we need to use following ignore statement
type TextFieldProps = InstanceType<typeof VTextField>['$props'];
interface Props extends /* @vue-ignore */ TextFieldProps {
  labelKey?: string;
  placeholderKey?: string;
  modelValue: string | null | undefined;
  required?: boolean;
  rules?: Array<(v: string) => true | string>;
  obscure?: boolean;
  icon?: string;
  click?: () => void;
}

const slots = useSlots();
const slotNames = Object.keys(slots).filter((slotName) => !!slotName);

const emit = defineEmits(['change', 'update:modelValue']);
const visible = ref(false);
const { requiredValidator } = useRule();

const localValue = computed({
  get() {
    return props.modelValue;
  },
  set(localValue) {
    emit('update:modelValue', localValue?.trim());
    emit('change', localValue?.trim());
  },
});

const props = defineProps<Props>();

const fieldRules = computed(() => [
  ...(props.required ? [() => requiredValidator(props.modelValue)] : []),
  ...(props.rules ?? []),
]);

const handleClick = () => {
  visible.value = !visible.value;
  props.click?.();
};
</script>
