import { type Duration, format, parse, formatISODuration } from 'date-fns';

const ISO_DURATION_REGEX =
  /P(?:([\d]+\.?[\d]*|\.[\d]+)Y)?(?:([\d]+\.?[\d]*|\.[\d]+)M)?(?:([\d]+\.?[\d]*|\.[\d]+)W)?(?:([\d]+\.?[\d]*|\.[\d]+)D)?(?:T(?:([\d]+\.?[\d]*|\.[\d]+)H)?(?:([\d]+\.?[\d]*|\.[\d]+)M)?(?:([\d]+\.?[\d]*|\.[\d]+)S)?)?$/;

const TIME_SPAN_FORMAT = 'HH:mm';

export const parseISODuration = (argument: string): Duration => {
  const parsedArgument = argument.replace(/,/g, '.'); // Decimal fraction may be specified with either a comma or a full stop

  const match = parsedArgument.match(ISO_DURATION_REGEX);

  if (!match) {
    throw new RangeError('Invalid format');
  }

  const [
    ,
    years = 0,
    months = 0,
    weeks = 0,
    days = 0,
    hours = 0,
    minutes = 0,
    seconds = 0,
  ] = match;

  const entries = Object.entries({
    years,
    months,
    weeks,
    days,
    hours,
    minutes,
    seconds,
  }) as [keyof Duration, string][];

  return entries.reduce<Duration>((prev, [key, value]) => {
    prev[key] = +value;
    return prev;
  }, {});
};

export const formatTimeDuration = (duration: Duration): string => {
  const date = new Date(
    duration.years || 0,
    duration.months || 0,
    duration.days,
    duration.hours,
    duration.minutes,
    duration.seconds,
  );

  return format(date, TIME_SPAN_FORMAT);
};

export const formatTimeIsoDuration = (time: string): string => {
  const date = parse(time, TIME_SPAN_FORMAT, new Date());

  return formatISODuration({
    years: date.getFullYear(),
    months: date.getMonth(),
    days: date.getDay(),
    hours: date.getHours(),
    minutes: date.getMinutes(),
  });
};

export const sumDurations = (durations: Duration[]): Duration => {
  const duration = {
    years: 0,
    months: 0,
    weeks: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };
  for (const item of durations) {
    duration.years += item.years || 0;
    duration.months += item.months || 0;
    duration.weeks += item.weeks || 0;
    duration.days += item.days || 0;
    duration.hours += item.hours || 0;
    duration.minutes += item.minutes || 0;
    duration.seconds += item.seconds || 0;
  }

  return duration;
};
